import * as React from "react";

import { Box, Button, useToast } from "@chakra-ui/react";
import { gql, useMutation } from "@apollo/client";

import { GET_USER } from "../hooks/useAuth";
import { IconX } from "@tabler/icons";
import { navigate } from "gatsby";
import { useEffect } from "react";

const LOG_OUT = gql`
  mutation logOut {
    logout(input: {}) {
      status
    }
  }
`;

const LogOut:React.FC = () => {
  const toast = useToast;
  const [logOut, { called, loading, error, data }] = useMutation(LOG_OUT, {
    refetchQueries: [
      { query: GET_USER }
    ],
  });
  const loggedOut = Boolean(data?.logout?.status);

    function handleClick() {
       logOut()
       navigate('/log-in');
  }

    return (
      <Box display="flex" justifyContent="center">
        <Button onClick={() => handleClick()} rightIcon={<IconX height="12px" width="12px"></IconX>}> Log Out </Button>
      </Box>
  );
}

export default LogOut