import * as React from 'react'

import { Box, Button, Container, Divider, Flex, Link, Stack, StackDivider, Text } from '@chakra-ui/react'
import { gql, useMutation } from '@apollo/client';
// import { URLSearchParams } from "url"
import useAuth, { User }  from '../hooks/useAuth';

import { IconClockHour3 } from '@tabler/icons';
import LogOut from '../Logout/LogOut';
import PasswordCard from './PasswordCard'
import PersonalInfoCard from './PersonalInfoCard'

const AccountForm = () => {
    const params = new URLSearchParams(window.location.search);
    console.log(params)
    const resetKey = params.get('keys') || '';
    const login = params.get('login') || '';

    return (
        <Container bg="gray.50" maxW="100%" py={{ base: '16', md: '16' }}>
            <Flex padding="32px" flexDirection={{md: "row", base: "column"}} justifyContent={{md:"flex-end", base: "center"}} alignItems="center">
                <Button as="a" href="/shopping-history" mr="12px" variant="outline" rightIcon={<IconClockHour3 height="12px" width="12px"></IconClockHour3>} _hover={{textDecoration: "none !important", backgroundColor: "gray.200"}}> View your shopping history </Button>
                {/* LOGOUT */}
                <LogOut></LogOut>
            </Flex>
        <Divider></Divider>
        <Stack spacing="5" divider={<StackDivider />}>
            <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '5', lg: '8' }} justify="space-between" p={{sm: '32px', base: "0px"}}>
                <Box flexShrink={0}>
                    <Text fontSize="lg" fontWeight="medium">
                        Personal Information
                    </Text>
                    <Text color="muted" fontSize="sm">
                        Your name and address for billing and shipping.
                    </Text>
                </Box>
                <PersonalInfoCard maxW={{ lg: '680px' }} />
            </Stack>

            <Stack direction={{ base: 'column', lg: 'row' }}  spacing={{ base: '5', lg: '8' }} justify="space-between" p={{sm: '32px', base: "0px"}}>
                <Box flexShrink={0}>
                    <Text fontSize="lg" fontWeight="medium">
                        Password
                    </Text>
                    <Text color="muted" fontSize="sm">
                        Change your password
                    </Text>
                </Box>
                <PasswordCard ></PasswordCard>
                </Stack>
               
            </Stack>

    </Container>
    )
}

export default AccountForm