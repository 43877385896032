import * as React from 'react'

import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorModeValue,
  useToast
} from '@chakra-ui/react'
import { gql, useMutation, useQuery } from '@apollo/client';
import useAuth, { User } from '../hooks/useAuth';

const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $id: ID!,
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $address1: String!, 
    $address2: String!, 
    $city: String!, 
    $state: String!,
    $postcode: String!
  ) {
    updateCustomer(
    input: {
        id: $id, 
        firstName: $firstName, 
        lastName: $lastName, 
        email: $email,
        shipping: {
            address1: $address1, 
            address2: $address2, 
            city: $city, 
            state: $state,
            postcode: $postcode
        }
        }
    ) {
    customer {
      firstName
      email
      id
      lastName
      shipping {
        address1
        address2
        city
        state
        postcode
      }
    }
  }
  }
`;

export const GET_CUSTOMER = gql `
  query GetCustomer($customerId: Int) {
  customer(customerId: $customerId) {
    firstName
     email
      billing {
        address1
        address2
        city
        company
        country
        postcode
      }
  }
}
`

const PersonalInfoCard = (props: BoxProps) => {
    const toast = useToast()

    const { user } = useAuth();
    const { id, firstName, lastName, email } = user as User;

    const [updateProfile, { data, loading, error }] = useMutation(UPDATE_PROFILE, {
        onCompleted: () => {
        toast({
            title: 'Your profile was successfully updated!',
            status: 'success',
        })
        window.location.reload()
            },
        onError: () => {
        toast({
            title: 'Error',
            description: 'There was an error updating you profile.',
            status: 'error',
        })
    },
    });
  
  const customer = useQuery(GET_CUSTOMER, {
    variables: {id}
  })
    
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data:any = new FormData(event.currentTarget);
    const values = Object.fromEntries(data);

    updateProfile({
      variables: { id, ...values, },
    }).catch(error => {
      console.error(error);
    });
  }

    return (
        <Box id="personalInfoCard" maxW="680px" boxShadow={useColorModeValue('sm', 'sm-dark')} borderRadius="lg" bg="white" {...props}>
            
        <form method="post" onSubmit={handleSubmit}>
            
        <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
            <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                <FormControl id="firstName">
                    <FormLabel>First Name</FormLabel>
                    <Input type="text" defaultValue={firstName || ''}  placeholder={firstName || ''} name="firstName" />
                </FormControl>
                <FormControl id="lastName">
                    <FormLabel>Last Name</FormLabel>
                    <Input type="text" defaultValue={lastName || ''} placeholder={lastName || ''} name="lastName" />
                </FormControl>
            </Stack>
            <FormControl id="email">
                <FormLabel>Email</FormLabel>
                <Input type="email" defaultValue={email || ''} placeholder={email || ''} name="email" />
            </FormControl>
            
            <FormControl id="addressLine1">
                <FormLabel>Adress Line 1</FormLabel>
                <Input type="text" defaultValue={customer.data?.customer.billing.address1 || ''} placeholder={customer.data?.customer.billing.address1 || ''} name="address1" />
            </FormControl>
            
            <FormControl id="addressLine2">
                <FormLabel>Adress Line 2</FormLabel>
                <Input type="text" defaultValue={customer.data?.customer.billing.address2 || ''} placeholder={customer.data?.customer.billing.address2 || ''} name="address2" />
            </FormControl>
            
            <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                <FormControl id="city">
                    <FormLabel>City</FormLabel>
                    <Input defaultValue={customer.data?.customer.billing.city || ''} placeholder={customer.data?.customer.billing.city || ''} name="city"/>
                </FormControl>
                <FormControl id="state">
                    <FormLabel>State / Province</FormLabel>
                    <Input defaultValue={customer.data?.customer.billing.state || ''} placeholder={customer.data?.customer.billing.state || ''} name="state"/>
                </FormControl>
                <FormControl id="zip">
                    <FormLabel> ZIP </FormLabel>
                    <Input name="postcode" defaultValue={customer.data?.customer.billing.postcode || ''} placeholder={customer.data?.customer.billing.postcode || ''} />
                </FormControl>
            </Stack>

            <FormControl id="country">
                <FormLabel>Country</FormLabel>
                <Input type="text" defaultValue={customer.data?.customer.billing.country || ''} placeholder={customer.data?.customer.billing.country || ''} name="country" />
            </FormControl>
          </Stack>
             {error ? (
          <p className="error-message">{error.message}</p>
        ) : null}
        <Divider />
        <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
            <Button type="submit" disabled={loading}>
                {loading ? 'Saving...' : 'Save'}
            </Button>
        </Flex>
        </form>
    </Box>
    )
}

export default PersonalInfoCard